$(document).ready(function(){

	$(window).scroll(function(){
		var newLocal = '.sticky', sticky = $(newLocal),
			scroll = $(window).scrollTop();
		
		if (scroll >= 100){
			sticky.addClass('fixed');
			$('#main').addClass('paddinTop');
		}
		else{
			sticky.removeClass('fixed');
			$('#main').removeClass('paddinTop');
		}
	});

	var swiper = new Swiper(".mySwiper", {
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		autoplay: {
			delay: 7000,
			disableOnInteraction: false,
		},
		});

	var swiperClientes = new Swiper(".swiperClientes", {
		slidesPerView: 5,
		spaceBetween: 30,
		slidesPerGroup: 1,
		loop: true,
		loopFillGroupWithBlank: true,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		autoplay: {
			delay: 1500,
			disableOnInteraction: false,
		},
		breakpoints: {
			// when window width is >= 320px
			320: {
			  slidesPerView: 2,
			  spaceBetween: 20
			},
			// when window width is >= 480px
			480: {
			  slidesPerView: 3,
			  spaceBetween: 30
			},
			// when window width is >= 640px
			992: {
			  slidesPerView: 3,
			  spaceBetween: 40
			},
			1200: {
				slidesPerView: 5,
				spaceBetween: 40
			  }
		  }
		});

	$(document).scroll(function(){
		$('.odometer').each( function () {
			var parent_section_postion = $(this).closest('section').position();
			var parent_section_top = parent_section_postion.top;
			if ($(document).scrollTop() > parent_section_top - 300) {
				if ($(this).data('status') == 'yes') {
					$(this).html( $(this).data('count') );
					$(this).data('status', 'no')
				}
			}
		});
	});

	function validateForm(){
		// Reset and hide all messages on .keyup()
		$(".js-form input").keyup(function() {
			$(".error-message p").fadeOut();
		});

		// Accept only figure from 0 to 9 and + ( ) in the phone field
		$(".js-form input[name='telefono'").keyup(function() {
			$("input[name='telefono'").val(this.value.match(/[0-9 + ( )]*/));
		});

		var outputError;
		var iconoTipo;

		if ($('.js-form').length) {
			$('.js-form').each(function(){
				var validator = $(this).bind("invalid-form.validate", function() {

					if (validator.numberOfInvalids() != 1)
					{
						outputError = "<i class='bi bi-x-circle-fill'></i> Lo sentimos pero, hay " + validator.numberOfInvalids() + " campos obligatorios por llenar.";
						
					}
					else
					{
						const newLocal = ".correoInput";
						if (this.querySelector(newLocal)){
							outputError = "<i class='bi bi-x-circle-fill'></i> Ingresar una dirección de correo válido";
						}
						else
						{
							outputError = "<i class='bi bi-x-circle-fill'></i> Lo sentimos pero, hay un " + validator.numberOfInvalids() + " campo obligatorio por llenar.";
						}
					};
					$(".error-message p").hide().html(outputError).fadeIn();

					
				}).validate({
					rules: {
						nombre: 'required',
						dni: 'required',
						telefono: { required: true },
						correo: { required: true, email: true },
						interesado: 'required',
						comentarios: 'required',
					},
					errorContainer: $(".error-message p"),
					errorPlacement: function(error, element) {
						error.remove();
					},
					submitHandler: function(form){
						$.ajax({
							url: "/new/action.php",
							dataType: "json",
							method: "post",
							data: $(form).serialize(),
							success: function(data)
							{
								var ResultadoResp = data.response;

								if (ResultadoResp == '1'){ 
									iconoTipo = $.sweetModal.ICON_SUCCESS;
			                		/***/
			                		$.sweetModal({
										content: '<div class="modalTit"><h4 class="pre"><span class="heading_32">¡Mensaje Recibido!</span></h4><p class="successTit3">Gracias por escribirnos. En el transcurso del día nos pondremos en contacto.</p></div>',
										icon: iconoTipo,
										width: '40%',
										theme: $.sweetModal.THEME_LIGHT,
										blocking: true,
										onClose: function(){
											$(form).trigger("reset");
										}
									});

			                	}

								$('button[type="submit"]').prop('disabled', false);
							},
							error: function(){
								$.sweetModal({
									content: 'Ha ocurrido un error, inténtalo más tarde.',
									icon: $.sweetModal.ICON_ERROR,
									width: '40%',
									theme: $.sweetModal.THEME_LIGHT,
									blocking: true,
									onClose: function(){
										$('button[type="submit"]').prop('disabled', false);
									}
								});
							},
							beforeSend:function(){
								$('button[type="submit"]').prop('disabled', true);
							}
						});
					}
				});
			});
		};
	};
	validateForm();


	/* ISOTOPE */
	var fselector = 0;
	var work_grid = $('.works-grid');
	function initWorkFilter(){
		(function($){
		 "use strict";
		 
		 work_grid.imagesLoaded(function(){
				work_grid.isotope({
					itemSelector: '.mix',
					layoutMode: 'fitRows',
					filter: fselector
				});
			});
			
		})(jQuery);
	};

	$(".filter").click(function(event){
		event.preventDefault();

		$(".filter").removeClass("active");
		$(this).addClass("active");
		fselector = $(this).attr('data-filter');
		
		work_grid.isotope({
			itemSelector: '.mix',
			layoutMode: 'fitRows',
			filter: fselector
		});
	});

	/* Galeria Interna Producto */
	/*var galleryTop = new Swiper('.gallery-top', {
		spaceBetween: 10,
		navigation: {
		  nextEl: '.swiper-button-next',
		  prevEl: '.swiper-button-prev',
		},
		loop: true,
		loopedSlides: 4
	  });
	  var galleryThumbs = new Swiper('.gallery-thumbs', {
		spaceBetween: 10,
		centeredSlides: true,
		slidesPerView: 'auto',
		touchRatio: 0.2,
		slideToClickedSlide: true,
		loop: true,
		loopedSlides: 4
	  });
	  galleryTop.controller.control = galleryThumbs;
	  galleryThumbs.controller.control = galleryTop;*/
	  /* Galeria Interna Producto */
		var galleryThumbs = new Swiper('.gallery-thumbs', {
			spaceBetween: 10,
			slidesPerView: 5,
			freeMode: true,
			watchSlidesProgress: true,
			slideToClickedSlide: true,
			//touchRatio: 0.2,
			slideToClickedSlide: true,
		});
		var galleryTop = new Swiper('.gallery-top', {
			spaceBetween: 10,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			thumbs: {
				swiper: galleryThumbs,
			},
		});

});